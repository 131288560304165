<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import Password from './Types/Password.svelte';
  import PhoneInput from './Types/PhoneInput.svelte';
  import CodeInput from './Types/CodeInput.svelte';
  import MessageTextArea from './Types/MessageTextArea.svelte';

  export let type = 'password';
  export let placeholder = '';
  export let label = '';
  export let value = '';
  export let showText = null;
  export let input_count = 3;
  export let can_submit = false;
  export let rows = 1;
  export let enterkeyhint = 'enter';

  const dispatch = createEventDispatcher();
  onMount(() => {
    // setTimeout(() => {
    console.log('starting Input Value: ', value);
    // }, 0);
  });
</script>

<style src="./Input.scss" global>
</style>

{#if label}
  <label style="margin-bottom: 10px" class="cm-InputLabel">{label}</label>
{/if}
{#if type === 'password'}
  <Password {value} {dispatch} {placeholder} {showText} {enterkeyhint} />
{/if}
<!-- on:value -->
{#if type === 'phoneInput'}
  <PhoneInput {dispatch} {value} />
{/if}

{#if type === 'codeInput'}
  <CodeInput {dispatch} {input_count} />
{/if}

{#if type === 'messageTextArea'}
  <MessageTextArea {value} {placeholder} {rows} {dispatch} {can_submit} />
{/if}
