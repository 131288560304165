<script>
  import { createEventDispatcher } from 'svelte';
  import _ from 'lodash';

  export let tabs = [];
  export let active_tab = 0;

  const dispatch = createEventDispatcher();

  function findTabIndex(tab) {
    return _.findIndex(tabs, t => {
      return t.id === tab.id;
    });
  }
</script>

<div class="cm-Grid SubNav">
  <div class="cm-Grid-row">
    {#each tabs as tab, i}
      <div
        on:click={e => {
          active_tab = i;
          dispatch('change', { value: tab });
        }}
        class="SubNav-item {tab.disabled ? "is-disabled": "" }"
      >
        <div class="SubNav-label">
          {tab.label}
          {#if tab.badge}
            <div
              class="cm-Badge cm-ListItem-right"
              style="color: white; margin-left: 5px; transform: translateY(-1px);"
              cm-background="Accent / Danger"
            >
              {tab.badge}
            </div>
          {/if}
        </div>
        {#if String(active_tab) === String(findTabIndex(tab))}
          <div class="SubNav-activeIndicator" />
        {/if}
      </div>
    {/each}
  </div>
</div>

<style src="./SubNav.scss" global>
</style>
