// Components ---------
import * as ActionSheet from './components/ActionSheet/ActionSheet.svelte';
import * as Feedback from './components/Feedback/Feedback.svelte';
import * as Badge from './components/Badge/Badge.svelte';
import * as BorderRadius from './components/BorderRadius/BorderRadius.svelte';
import * as Button from './components/Button/Button.svelte';
import * as Colors from './components/Colors/Colors.svelte';
import * as Dotnav from './components/Dotnav/Dotnav.svelte';
import * as Font from './components/Font/Font.svelte';
import * as Grid from './components/Grid/Grid.svelte';
import * as Input from './components/Input/Input.svelte';
import * as Margin from './components/Margin/Margin.svelte';
import * as Padding from './components/Padding/Padding.svelte';
import * as Position from './components/Position/Position.svelte';
import * as Search from './components/Search/Search.svelte';
import * as Select from './components/Select/Select.svelte';
import * as Text from './components/Text/Text.svelte';
import * as Hints from './components/Hints/Hints.svelte';
import * as Width from './components/Width/Width.svelte';
import * as SlideOver from './components/SlideOver/SlideOver.svelte';
import * as Toggle from './components/Toggle/Toggle.svelte';
import * as ProgressBar from './components/ProgressBar/ProgressBar.svelte';
import * as Avatar from './components/Avatar/Avatar.svelte';
import * as Card from './components/Card/Card.svelte';
import * as SubNav from './components/SubNav/SubNav.svelte';
import * as Divider from './components/Divider/Divider.svelte';
import * as BoxShadow from './components/BoxShadow/BoxShadow.svelte';
import * as Dropdown from './components/Dropdown/Dropdown.svelte';
import * as Icon from './components/Icon/Icon.svelte';
import * as Comments from './components/Comments/Comments.svelte';
import * as MessageBar from './components/MessageBar/MessageBar.svelte';
import * as Navbar from './components/Navbar/Navbar.svelte';
import * as Messages from './components/Messages/Messages.svelte';
import * as Reply from './components/Reply/Reply.svelte';
import * as Container from './components/Container/Container.svelte';
import * as ToggleButtons from './components/ToggleButtons/ToggleButtons.svelte';
import * as IconFooter from './components/IconFooter/IconFooter.svelte';
import * as ImageGrid from './components/ImageGrid/ImageGrid.svelte';
import * as Drawer from './components/Drawer/Drawer.svelte';

import * as ListItem from './components/ListItem/ListItem.svelte';

// Needs
import { registerComponent, init } from './tools/core';
import * as utils from './tools/utils';

const renderableComponents = {
  ActionSheet,
  ProgressBar,
  Select,
  Input,
  ListItem,
  SubNav,
  MessageBar,
  ToggleButtons,
  Drawer,
};

const CmKit = {
  utils: utils,
};

for (let key in renderableComponents) {
  CmKit[key] = registerComponent(renderableComponents[key]);
}

init(CmKit);

window['CmKit'] = CmKit;
export default CmKit;
