export function templater(node, args) {
  const res = {
    update({ template, data }) {
      try {
        while (typeof template === 'function' && !template.__templater) {
          template = template(data, node);
        }

        if (typeof template === 'string') {
          node.innerHTML = template;
        } else if (template && template.__templater) {
          template(node);
        }
      } catch (e) {
        node.innerHTML = 'Bad template';
      }
    },
    destroy() {
      // anything to do on destroy?
    },
  };

  res.update(args || {});

  return res;
}
