<script>
  import { createEventDispatcher } from 'svelte';
  export let options = [
    { title: 'This is the title', value: 'InitialValue' },
    { title: 'second option', value: 'value 2' },
  ];
  export let value = [];
  export let title = 'Title';
  export let clear_button = false;

  const dispatch = createEventDispatcher();

  $: valueLookup = createLookup(options, value);

  function createLookup(options, value) {
    let o = {};
    options.forEach(opt => {
      o[opt.value] = value.findIndex(d => d === opt.value) >= 0;
    });
    return o;
  }

  function dispatchUpdate() {
    dispatch('change', {
      value,
      valueLookup: createLookup(options, value),
    });
  }
</script>

<style src="./ToggleButtons.scss" global>
</style>

<div class="cm-ToggleButtons">
  {#if clear_button}
    <div class="cm-Grid cm-Grid--noPadding cm-Margin--small-bottom">
      <div class="cm-Grid-row">
        <div cm-font="Body / 14 Regular" class="cm-Width-expand">{title}</div>
        {#if value.length}
          <div
            cm-font="Body / 14 Regular"
            cm-color="Neutral / Storm Grey"
            style="cursor:pointer"
            on:click={() => {
              value = [];
              dispatchUpdate();
            }}>
            Clear
          </div>
        {/if}
      </div>
    </div>
  {:else}
    <div cm-font="Body / 14 Regular" class="cm-Margin--small-bottom">
      {title}
    </div>
  {/if}

  <div class="cm-ToggleButtons-buttons">
    {#each options as option}
      <button
        class="cm-Button cm-Button--5"
        cm-color="Primary / CDLLife Blue"
        on:click={() => {
          const idx = value.indexOf(option.value);
          if (idx >= 0) {
            value.splice(idx, 1);
          } else {
            value.push(option.value);
          }
          value = value;
          dispatchUpdate();
        }}
        class:cm-Button--alternate={!valueLookup[option.value]}>
        {option.title}
      </button>
    {/each}
  </div>
</div>
