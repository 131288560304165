<script>
  import { onMount } from 'svelte';
  import _ from 'lodash';

  export let dispatch = () => {};
  export let value = '';

  let formattedPhone = '',
    previousformattedValue = '';

  onMount(() => {
    formattedPhone = value;
    formatDisplayedPhoneNumber();
  });

  function keyevent(e) {
    if (e.key === 'Backspace' && value.length > 0) {
      const lastVal = previousformattedValue[previousformattedValue.length - 1];
      if (!/^[0-9]$/.test(lastVal)) {
        formattedPhone = formattedPhone.slice(0, -2);
        value = gatherValueFromFormattedText(formattedPhone);
      }
    }
  }

  function formatPhoneText(value) {
    const areaCode = value.substr(0, 3),
      cityNumber = value.substr(3, 3),
      phoneLine = value.substr(6, 4);
    let formattedPhone;

    if (areaCode) {
      formattedPhone = '('.concat(areaCode);
    } else {
      formattedPhone = '';
    }
    if (areaCode.length === 3) {
      formattedPhone = formattedPhone.concat(')');
    }
    if (cityNumber) {
      formattedPhone = formattedPhone.concat(' ' + cityNumber);
    }
    if (cityNumber.length === 3) {
      formattedPhone = formattedPhone.concat('-');
    }
    if (phoneLine) {
      formattedPhone = formattedPhone.concat(phoneLine);
    }

    return formattedPhone;
  }

  function gatherValueFromFormattedText(s) {
    return String(s).replace(/[^0-9]/g, '');
  }

  function formatDisplayedPhoneNumber() {
    previousformattedValue = formatPhoneText(value);
    value = gatherValueFromFormattedText(formattedPhone);

    formattedPhone = formatPhoneText(value);

    dispatch('value', { value: value, valid: isValidPhone(value) });
  }

  function isValidPhone(value) {
    return String(value).length === 10;
  }
</script>

<div class="cm-Grid cm-Grid--noPadding">
  <div class="cm-Grid-row">
    <div style="width:35px">
      <input
        class="cm-Input cm-Input--flat cm-Input--flat--verificationPhone"
        name="prefix"
        disabled
        value="+ 1"
        type="text" />
    </div>
    -
    <div class="cm-Width-expand">
      <input
        class="cm-Input cm-Input--flat cm-Input--flat--verificationPhone"
        name="phoneNumber"
        bind:value={formattedPhone}
        on:keyup={keyevent}
        on:input={formatDisplayedPhoneNumber}
        on:change={formatDisplayedPhoneNumber}
        type="tel" />
    </div>
  </div>
</div>
