import { writable } from 'svelte/store';

export const settings = registerStore({
  defaultContainer: document.body,
});

export const kit = writable(null);

// -------------------

function registerStore(def) {
  const store = writable(def);
  let recent = def;

  store.getValue = () => {
    return recent;
  };

  store.merge = v => {
    store.set(Object.assign(recent, v));
  };

  store.subscribe(v => {
    recent = v;
  });

  return store;
}
