import { settings } from './stores';
import { render, html } from 'lit-html';

export function updateSettings(cfg) {
  settings.merge(cfg);
}

export function template() {
  const temp = html.apply(this, arguments),
    ret = node => {
      if (node) {
        render(temp, node);
      }
    };

  ret.__templater = 1;
  return ret;
}
